:root {
  --primary-color: #464242;
  --secondary-color: #797979;
  --tertiary-color: #04abbb;

  --sidebar-color : #E8E8E8;
  --secondary-text-color : #797979;
  --table-header : #464242;
  --button-primary-color : #464242;

}

/* Add Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import custom fonts */
@font-face {
  font-family: "ProductSansRegular";
  src: url("fonts/ProductSansRegular.ttf");
}

@font-face {
  font-family: "Silvertone";
  src: url("fonts/Silvertone.ttf");
}

body {
  margin: 0;
  font-family: "ProductSansRegular",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
#root {
  margin: 0;
  font-family: "ProductSansRegular", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  max-height: 100%;
  background-color: white;
}

.site-header {
  background-color: #0a81d1 !important;
}

/* Ant Design CSS Overrides */

.ant-layout {
  min-height: 100vh !important;
  max-height: 100% !important;
  overflow-y: hidden !important;
  background-color: white !important;
}

.ant-layout-sider-children {
  width: 100% !important;
}

.ant-btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-btn-primary:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.ant-btn-primary:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-input-search-button, .ant-input-search-button:hover{
  background-color: var(--button-primary-color) !important;
  border-color: var(--button-primary-color) !important;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--table-header) !important;
  color: white;
}

.ant-btn{
  outline: none !important;
  border: none !important;
}

.ant-modal-confirm-btns .ant-btn-primary{
  background-color: var(--button-primary-color) !important;
  border-color: var(--button-primary-color) !important;
}

.ant-table-tbody > tr.ant-table-row > td{
  background: #d9d9d9 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
  background: #fafafa !important;
}

/* Login Page Styling */
.login-bg {
  background-image: linear-gradient(to right bottom, #eeeded, #f2f1f2, #f6f6f6, #fbfafb, #ffffff);
}

.login-center-div {
  box-shadow: 0px 0px 25px -3px rgba(123, 130, 131, 0.51);
  -webkit-box-shadow: 0px 0px 25px -3px rgba(123, 130, 131, 0.51);
  -moz-box-shadow: 0px 0px 25px -3px rgba(123, 130, 131, 0.51);
}

.login-logo-bg {
  background-color: #e8e8e8;
}

.cursive-font {
  font-family: "Silvertone", cursive;
  color : #838383 !important;
}

/* Sidebar Styling */
.sidebar {
  border-right: 1px solid var(--sidebar-color) !important;
}

.side-link{
  color: black !important;
  margin-bottom: 0 !important;
  cursor: pointer !important;
}

.ant-layout-sider,
.sidebar,
.custom-menu,
.custom-submenu {
  background-color: var(--sidebar-color) !important;
}

.custom-menu {
  color: black !important;
}

.custom-menu .ant-menu-title-content a {
  color: black !important;
}

.custom-submenu,
.custom-submenu .ant-menu-item-icon,
.custom-submenu .ant-menu-item-icon,
.custom-submenu .ant-menu-submenu-arrow::after,
.custom-submenu .ant-menu-submenu-arrow::before,
.custom-submenu .ant-menu-title-content:hover {
  color: black !important;
}

.custom-submenu .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.custom-submenu .ant-menu-inline .ant-menu-item {
  margin-top: 0;
  margin-bottom: 0;
}

.sidebar-header {
  padding: 10px;
  color: black;
}

.sidebar-header .header-details {
  flex: 1;
  margin-left: 15px;
  margin-top: 8px;
}

.sidebar-header .header-details .user-name {
  margin-bottom: 0 !important;
}

.sidebar-header .header-details .job-title {
  font-size: 10px;
  display: block;
  margin-top: -5px;
}

.sidebar-header .header-image {
  padding-left: 10px;
}

.sidebar-header .header-image img {
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.sidebar-header .header-actions {
  display: inline-grid;
  justify-items: center;
}

.sidebar-header .header-actions .svg-inline--fa {
  font-size: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.sidebar-search {
  padding: 16px;
}

.sidebar-search input {
  border-radius: 16px;
}
